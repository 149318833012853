import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started with Android Application Development - Android Studio Edition Pt 1",
  "date": "2014-07-03T20:32:30-05:00",
  "tags": ["android", "android-studio"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi and welcome to my blog. In this blog post I am going to describe how to get start on developing applications on the Android platform using Android Studio.`}</p>
    <p>{`##Introductions
###What is Android Studio?
Android studio is an integrated development environment(IDE) based on IntelliJ IDEA that will be the next official IDE for Android Development. The IDE is currently in the beta release at the time of this post and may require update or reinstallation once it is stable.`}</p>
    <p>{`You may develop phone/tablet apps, TV apps, Glass apps and Wear apps using the Android Studio.`}</p>
    <p>{`###Installation
First, you will need a development environment with Java Development Kit(JDK) version 6 or 7 installed. You may download it from `}<a parentName="p" {...{
        "href": "http://www.oracle.com/technetwork/java/javase/downloads/index-jsp-138363.html#JDK7"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Next, you will need to download the Android Studio Bundle `}<a parentName="p" {...{
        "href": "http://developer.android.com/sdk/installing/studio.html#download"
      }}>{`here`}</a>{` (recommended). Once the installation is completed, open up the IDE and configure the SDK.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/11f4bde1e5406aa8ecc7f99387b5715d/8ce22/02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACR0lEQVQoz42QyU8UQRSH+w8TxIMe/Ru8uxGjHkw86AUTj4SDEkRRQkK4SKJkBhIUmOmanmm6p6arq2vpdZZeGBh6hgSZhTbdBAgewC9fvbxD/fJelfBu6fuHleL08taTmbX7b1buvlq683Jx4sW3iedfbz9bGJ/8PPZ0fuzx3K2Hs+OPZu9Nzj14uzj16cfH6fmlqfdCUUF5+GW7tqZjEyKsYXImwkRDhqYbGiY1lNa010mmgSivICJsKzvL8uufcAZWlcLWb0kqlTOrqiIWCxIQy5JUAkUJiACIlBiObVmWaVsmxlio6UyhvzRecWyXMcb52WE8g6UtS6+bJmPMNE0rw7YtbBBB1jmCqCLtqqpqWhYy0ts0g5wjlyuqWqWUGpdggzIBEX501DvsdHq9bhS1dQoJ02wrXc47Jxtle1dhpiXULZYkyenpaZIk3ThGjKhY55QxysJzoigKrxJFoeV4gmubSZIMh8PRaHQYx4xgo6ZYthMG1xEGgem4guc6/X7/+PhPv3/SOYw5QQyrlPDgv8KNxsXacRxjhKlBPc+re3Xf928I625w+eZujDSsQsg515HebDZvCNNGlIWT7MO60KhJsOzaLmf8uslhmIZtf/8i3Ov1ZFVRFIVRqmnIazSCMPCDf235fqvVMh1PcIJ2MhoMByeDQb9zcOB5ruu4YRCFfnMv9NtRcOF+FKbuhe2s1ut1YWEDrG6Ka1tgG5R2AAC7MpDFzZ31XEHekLR1APMA5kWYF6u5YjVXVHOF1HxBXd0s/wUc7vw06UEZmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "02",
            "title": "02",
            "src": "/static/11f4bde1e5406aa8ecc7f99387b5715d/fcda8/02.png",
            "srcSet": ["/static/11f4bde1e5406aa8ecc7f99387b5715d/12f09/02.png 148w", "/static/11f4bde1e5406aa8ecc7f99387b5715d/e4a3f/02.png 295w", "/static/11f4bde1e5406aa8ecc7f99387b5715d/fcda8/02.png 590w", "/static/11f4bde1e5406aa8ecc7f99387b5715d/8ce22/02.png 685w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You may want to download and install all the tools provided in case you may need them in the future. This process may take awhile so let’s grab a cup of coffee.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ae1e5b3928684578960b7572dcbfc354/6bff2/03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.86486486486486%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACGElEQVQoz3VST2/TMBzNl0Uak/YxOCCBYANx5AAIEOKGNLrDJo0LQvyr6Jqujh0nTR3Hses4iZ0mWROjtNsujKd3eL9nP1m/JzsPXx6/eH/26NXpweHne4+P956M9p6O7h+e7B+e7B9t+WzLQY/2j0YHz08evPny+uPph7efnG8/L3jKhZByJcuiMFobXepy4FZrU5ZmN96IPC/y0iSZcSbzGWdxUWpjTF3X6wF1qbVSaiVElqn1YNZVNfjXrIeLNGFOSFMu0jAMF0EoZdZ1Xb9F3bQiXizDcDfe+jvYvieUOYgIqRIAwHQyJUtire26zlprTEUDD87nV1ebnXmb7PreWpvK3IGES8UwxhAizrm1drPZ9H1vtE5ZinGQF8WdYSaUg+hqlSUe9OaX8yRh1tp+e6a15pwDAKSU/w2DJeOcYBz4yGeMtW3bNE3XdU3bFEVOKa2q6q5wn8rCgTEnxAOXAHoQI18IUdeN0aZu2kIkURD8W9hQSt8POwdcJZRCDyIPIohoPDy1XldN26ok8mazXeo2POxl7XVhrk8WwQIAAD2EkB/HlDFOEkF4LkVKSWxMZUw1fIOd0DrLlFIqTqXzHcwWS8xoEhMSE6LkKiIMLzkXMsuyXKlMSnVDKTMhVktCRRKHceq8m5yfX/7448LxDIxdb+yC3y74NZ2PZ9D1I9ePpn40RdEFiqYo8DBAGMAQYAC+juFfX9TCld/2maEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "03",
            "title": "03",
            "src": "/static/ae1e5b3928684578960b7572dcbfc354/fcda8/03.png",
            "srcSet": ["/static/ae1e5b3928684578960b7572dcbfc354/12f09/03.png 148w", "/static/ae1e5b3928684578960b7572dcbfc354/e4a3f/03.png 295w", "/static/ae1e5b3928684578960b7572dcbfc354/fcda8/03.png 590w", "/static/ae1e5b3928684578960b7572dcbfc354/efc66/03.png 885w", "/static/ae1e5b3928684578960b7572dcbfc354/6bff2/03.png 957w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`####Optional
You may also want to install `}<a parentName="p" {...{
        "href": "http://www.genymotion.com/"
      }}>{`Genymotion`}</a>{`, a virtual machine for Android based on virtual box as an alternative to Android Virtual Device or AVD (will be covered next).`}</p>
    <p>{`Once everything is installed you may start to develop your first android app!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      